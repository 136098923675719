import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, ScrollRestoration } from "react-router-dom";
import axios from "axios";
import { SiteDataProps } from "../../models/LayoutModels";

const Layout = () => {
  const [siteData, setSiteData] = useState<SiteDataProps>({});
  const [error, setError] = useState("");
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const res = await axios.get(
          "https://admin.inayaitconstructions.britishmarketplace.co.uk/api/get-settings"
        );
        setSiteData(res.data.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
          setError("");
        } else {
          setError("Something went wrong");
        }
      }
    };
    fetchSiteData();
  }, []);
  return (
    <div>
      <Header siteData={siteData} />
      <ScrollRestoration />
      {error && (
        <div
          style={{
            borderRadius: "4px",
            padding: "8px",
            background: "red",
            borderWidth: "1px",
          }}
        >
          {error}
        </div>
      )}
      <Outlet />
      <Footer siteData={siteData} />
    </div>
  );
};

export default Layout;
